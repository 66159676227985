import React from "react";
import "./style.css";

const ProfileCards = () => {
  return (
    <section className="profile-section">
   <div className="ContactUs bodyy" id='Contacts'>
      <div id="contactHead">
      <h3 className="sectionHeading">NEED HELP? WE GOT YOU!</h3>
      </div>
      <div className="cards">

        <div className="Profile-card">
          <div className="imgBx">
            <img
              src="https://live.staticflickr.com/65535/53874186624_6d1e76647c_z.jpg"
              ></img>
          </div>
          <div className="Card-content">
            <div className="details">
              <p className="card-name">
                Aman Kumar
              </p>
              <p className="credentials">
                Head, Hospitality
              </p>
              <div className="Card-Icons">
                  <ul className="social_icons">
                    <li>
                      <a href="tel:+919142397814" target="_blank">
                        <i className="fa fa-solid fa-phone fa-flip-horizontal Icons"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/aman-kumar-074116228" target="_blank">
                        <i className="fab fa-linkedin Icons"></i>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:kaman21@iitk.ac.in" target="_blank">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="Profile-card">
            <div className="imgBx">
              <img src="https://live.staticflickr.com/65535/53874246530_931777f202_z.jpg" target="_blank"></img>
            </div>
            <div className="Card-content">
              <div className="details">
                <p className="card-name">Sunil</p>
                <p className="credentials">Head, Hospitality</p>
                <div className="Card-Icons">
                  <ul className="social_icons">
                    <li>
                      <a href="tel:+917339936043">
                        <i className="fa fa-solid fa-phone fa-flip-horizontal Icons" target="_blank"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/sunil-janghu-245989241" target="_blank">
                        <i className="fab fa-linkedin Icons"></i>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:sunilj21@iitk.ac.in" target="_blank">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileCards;
