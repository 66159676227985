import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Header from "./components/Header";
import Featured from "./components/Featured";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Navbar2 from "./components/Navbar2";
import Opsnsorss from "./components/sponsors";
// import Preloader from "./components/preloader/preloader";
// import useLocoScroll from "./hooks/useLocoScroll";
import ProfileCards from "./components/ProfileCards";
// import photo from "./Assests/13723081_5333978.jpg";
import "./Home.css";
import Testimonials from "./components/Testimonials";
import { gsap } from "gsap";


const Home = () => {

  // const ref = useRef(null);
  // const[preloader, setPreloader] = useState(true);

  // useEffect(() => {
  //   if (!preloader && ref) {
  //     if (typeof window === "undefined" || !window.document) {
  //       return;
  //     }
  //   }
  // }, [preloader]);


  // useLocoScroll(!preloader);
  // const[timer, setTimer] = useState(3);
  // const id = useRef(null);

  // const clear = () => {
  //   window.clearInterval(id.current);
  //   setPreloader(false);
  // }

  // useEffect(() => {
  //   id.current = window.setInterval(() => {
  //     setTimer((timer) => timer - 1)
  //   }, 1000)
  // }, )

  // useEffect(() => {
  //   if(timer == 0) clear();
  // }, [timer])

  useEffect(() => {
    const tl = gsap.timeline({defaults:{duration: 2, delay: 5}});
    tl.to(".main-background", {
      scale: 1.05,
      opacity: 1,
      repeat: -1,
      ease: "ease",
      yoyo: true
    })

  }, []);

  return (

    // preloader ? <div>
    //   <Preloader />
    // </div> :
    < div data-scroll-container>
      <Navbar2 />
      <Header />
      <Featured />
      <Testimonials />
      <Gallery />
      <ProfileCards />
      <Opsnsorss />
      <Footer />
    </div>
  );
};

export default Home;
